import { graphql, PageProps } from "gatsby";
import * as React from "react";
import Hero from "../components/hero";
import Layout from "../components/layout";
import Modal from "../components/modal";
import ProductsRows from "../components/products-rows";
import SEO from "../components/seo";
import { IndexPageDataType } from "../types/pages";

const IndexPage = ({ data: { pageData, allWcProducts: { edges: products } } }: PageProps<IndexPageDataType>) => {
  const dates = {
    first_publication_date: pageData?.date || (new Date()).toISOString(),
    last_publication_date: pageData?.modified || (new Date()).toISOString(),
  };

  return (
    <Layout className={"page-holder"} id={"home-page"}>
      <SEO
        title={pageData?.title || ""}
        banner={pageData?.featured_media || ""}
        node={dates}
        pathname={"/"}
        description={pageData?.content || ""}
      />
      <div className="container">

        <Modal />

        <Hero
          src={pageData?.featured_media || ""}
          alt={pageData?.title || ""}
        />

        <section className="py-5">
          <header dir="rtl">
            <p className="small text-muted small text-uppercase mb-1">منتجاتنا</p>
            <h1 className="h5 text-uppercase mb-4">المنتجات الأكثر طلبًا</h1>
          </header>
          <ProductsRows
            products={products}
            columnClassName={"col-xl-3 col-lg-4 col-sm-6"}
          />
        </section>

      </div >
    </Layout >
  )
}

export const pageQuery = graphql`
  query ProductListings {
    pageData: zzPage(
      page_type: {eq: "home"},
      status: {eq: "publish"}
    ) {
      id
      title
      type
      date
      content
      status
      page_type
      zzenz_id
      featured_media
    }
    allWcProducts(
      sort: {fields: [total_sales], order: DESC},
      filter: {
        wordpress_id: {
          nin: [0, -1]
        }
      },
      limit: 8
    ) {
      edges {
        node {
          id
          wordpress_id
          slug
          name
          price
          regular_price
          description
          status
          images {
            id
            src
            height
            width
          }
        }
      }
    }
  }
`;

export default IndexPage;