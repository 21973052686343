import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { useEffect, useReducer, useRef } from "react";
import { generateProductImageSet, getImageLink } from "../../helpers/images";
import ProductCardPropsType from "./types";
import { graphql, Link, useStaticQuery } from "gatsby";
import { globalReducer } from "../../helpers/reducers";
import httpRequest from "../../helpers/http";
import { LineItemType } from "../templates/product/types";
import CartStorage from "../../helpers/storage";

import "./style.css";

const initialState = {
  loading: false,
  errors: null,
  data: {
    quantity: 1
  },
  message: null
};
export default function ProductCard({ name, price, original_price, description, slug, imageSrc, wordpress_id, link = "#", className, id, imageHeight, imageWidth }: ProductCardPropsType) {
  const [state, dispatch] = useReducer(globalReducer, initialState);
  const button = useRef<HTMLButtonElement>(null!);
  const isSold = !!original_price && price < original_price;
  const { storeDetails: { product_stock_url } } = useStaticQuery(graphql`
    {
      storeDetails: zzStoreWebsiteDetails {
        product_stock_url
      }
    }
  `);
  const imageConfig: IGatsbyImageData = {
    height: imageHeight || 380,
    width: imageWidth || 414,
    backgroundColor: "#f4f5f9",
    layout: "fullWidth",
    images: {
      fallback: {
        src: imageSrc,
      },
      sources: [
        {
          media: "",
          type: "image/webp",
          /**
           * old code generateProductImageSet(imageSrc, ["992"], [{ height: 300 }]).srcSet
           * create a pixelized(blur) image
           */
          srcSet: getImageLink(imageSrc)
        },
      ]
    }
  };
  let timerId: any;
  const onAddToCart = async () => {
    dispatch({
      type: "LOADING",
      payload: true
    });
    const lineItem: LineItemType = {
      product_id: wordpress_id as number,
      quantity: 1,
      meta_data: []
    };
    const response = await httpRequest({
      url: `${product_stock_url}/${lineItem.product_id}/availability?quantity=${lineItem.quantity}`,
      requestConfig: {
        method: "GET",
      }
    });

    if (response?.code !== "success") {
      return dispatch({
        type: "ERROR",
        payload: response.errors
      });
    }

    const cart = new CartStorage();
    const productDetails = {
      id: String(wordpress_id),
      slug: String(slug),
      name: name,
      regular_price: Number(price),
      thumbnail: {
        src: imageSrc,
        height: Number(imageHeight),
        width: Number(imageWidth),
      },
    };
    await Promise.all([
      cart.addItem(lineItem as LineItemType),
      cart.pushItemDetails(productDetails)
    ]);

    if (!button?.current?.classList?.contains('loading')) {
      button?.current?.classList?.add('loading');
      timerId = setTimeout(() => {
        button?.current?.classList?.remove('loading');
        dispatch({
          type: "SUCCESS",
          payload: "Ajouter au panier avec succes",
        });
        clearTimeout(timerId);
      }, 3700);
    }
  }

  useEffect(() => {
    return () => {
      clearTimeout(timerId);
    }
  }, []);


  return (
    <div className={`product text-center ${className || ""}`} id={id}>
      <div className="position-relative mb-3">
        {
          isSold && (
            <div className="badge text-white bg-danger">Sold</div>
          )
        }
        <Link to={link} className="d-block product__image-container">
          <GatsbyImage
            image={imageConfig}
            alt={name || ""}
            className={"h-100"}
            loading={"lazy"}
          />
        </Link>
        <div className="product-overlay">
          <ul className="mb-0 list-inline">
            <li className="list-inline-item m-0 p-0">
              <a
                href={link}
                className="btn btn-sm btn-outline-dark"
                target={"_blank"}
                rel="noopener"
                aria-label="ouvrir dans une nouvelle funetre"
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z" />
                </svg>
              </a>
            </li>
            <li className="list-inline-item m-0 p-0">
              {
                !!state.errors?.global
                  ? (
                    <div className={`postition-absolute alert alert-danger p-2`}>
                      {state.errors?.global}
                    </div>
                  )
                  : (
                    <button
                      className="btn btn-sm btn-dark btn-cart-animation"
                      type="button"
                      onClick={onAddToCart}
                      disabled={state.loading}
                      ref={button}
                    >
                      <span className="position-relative d-block" dir="rtl">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;أضف إلى السلة
                      </span>
                      <div className="cart">
                        <svg viewBox="0 0 36 26">
                          <polyline points="1 2.5 6 2.5 10 18.5 25.5 18.5 28.5 7.5 7.5 7.5" />
                          <polyline points="15 13.5 17 15.5 22 10.5" />
                        </svg>
                      </div>
                    </button>
                  )
              }
            </li>
            <li className="list-inline-item me-0">
              <a
                className="btn btn-sm btn-outline-dark"
                href="#productView"
                data-bs-toggle="modal"
                data-bs-product={JSON.stringify({
                  name,
                  regular_price: price,
                  description,
                  slug: slug,
                  wordpress_id,
                  thumbnail: {
                    src: getImageLink(imageSrc),
                    height: imageHeight,
                    width: imageWidth,
                  }
                })}
                aria-label="ouvrir dans une popup"
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 448 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 180V56c0-13.3 10.7-24 24-24h124c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H64v84c0 6.6-5.4 12-12 12H12c-6.6 0-12-5.4-12-12zM288 44v40c0 6.6 5.4 12 12 12h84v84c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12V56c0-13.3-10.7-24-24-24H300c-6.6 0-12 5.4-12 12zm148 276h-40c-6.6 0-12 5.4-12 12v84h-84c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h124c13.3 0 24-10.7 24-24V332c0-6.6-5.4-12-12-12zM160 468v-40c0-6.6-5.4-12-12-12H64v-84c0-6.6-5.4-12-12-12H12c-6.6 0-12 5.4-12 12v124c0 13.3 10.7 24 24 24h124c6.6 0 12-5.4 12-12z" />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <strong className="h6 first-letter__text_capitalize">
        <Link to={link} className="reset-anchor d-block">{name}</Link>
      </strong>
      <p className="small text-muted">{price}DZD</p>
    </div >
  )
}
