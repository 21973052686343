import React from "react"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { getExtension, getImageLink } from "../../helpers/images";
import HeroPropsTypes from "./types";

import "./style.css";

export default function Hero({ src, alt }: HeroPropsTypes) {
  const HeroImage = {
    backgroundColor: "#f4f5f9",
    layout: "constrained",
    images: {
      fallback: {
        src: src,
      },
      sources: [
        {
          media: "",
          type: "image/webp",
          srcSet: getImageLink(src),
        },
      ]
    }
  };

  return (
    <section className="hero pb-3 position-relative bg-cover bg-center d-flex align-items-center">
      <GatsbyImage
        image={HeroImage as IGatsbyImageData}
        alt={alt}
        className={"hero__image-bg"}
      />
    </section>
  )
}
